<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Chỉ gửi hóa đơn mới nhất chưa thanh toán") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.sendLatestInvoiceOnly"
              name="sendLatestInvoiceOnly"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.sendLatestInvoiceOnly"
              name="sendLatestInvoiceOnly"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Thông báo nhắc đến ngày lập hóa đơn") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoNotificationNextBillingDate"
              name="autoNotificationNextBillingDate"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoNotificationNextBillingDate"
              name="autoNotificationNextBillingDate"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.autoNotificationNextBillingDate" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="autoNotificationNextBillingDateAt"
            rules="required"
          >
            <!-- <b-form-group>
              <template v-slot:label>
                {{ t('Thông báo trước bao nhiêu ngày? (Cấu hình nhiều ngày cách nhau bằng dấu phẩy ,)') }}
              </template>
              <b-form-input
                id="autoNotificationNextBillingDateBeforeDays"
                v-model="data.autoNotificationNextBillingDateBeforeDays"
                placeholder="0,1,5"
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group> -->

            <select-date-time
              v-model="data.autoNotificationNextBillingDateAt"
              required
              :default-value="data.autoNotificationNextBillingDateAt"
              :label="t('Giờ thông báo (Sẽ lặp lại hàng tháng kể từ ngày này)')"
              :state="getValidationState(validationContext)"
              :error="validationContext.errors[0]"
            />
          </validation-provider>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Thông báo nhắc hạn thanh toán hóa đơn") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoNotificationInvoiceDueDate"
              name="autoNotificationInvoiceDueDate"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoNotificationInvoiceDueDate"
              name="autoNotificationInvoiceDueDate"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.autoNotificationInvoiceDueDate" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="autoNotificationInvoiceDueDateDays"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{
                  t(
                    "Thông báo trước bao nhiêu ngày? (Cấu hình nhiều ngày cách nhau bằng dấu phẩy ,)"
                  )
                }}
              </template>
              <b-form-input
                id="autoNotificationInvoiceDueDateDays"
                v-model="data.autoNotificationInvoiceDueDateDays"
                placeholder="0,1,5"
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{
            t("Gửi xác nhận thanh toán Zalo OA dạng tin giao dịch (Có phí)")
          }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.applyPaymentConfirmTable"
              name="applyPaymentConfirmTable"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.applyPaymentConfirmTable"
              name="applyPaymentConfirmTable"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Gửi thông báo có bao gồm ảnh QRCode thanh toán") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.notifyWithQrcode"
              name="notifyWithQrcode"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.notifyWithQrcode"
              name="notifyWithQrcode"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{
            t("Cho phép tra cứu thông báo phí quá hạn qua ZaloOA")
          }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.checkTuitionExpired"
              name="checkTuitionExpired"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.checkTuitionExpired"
              name="checkTuitionExpired"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-1">
        <b-col md="6">
          <!-- Description -->
          <validation-provider #default="validationContext" name="" rules="">
            <b-form-group :label="t('Mẫu thông báo qua Zalo BOT/Zalo OA')">
              <b-form-textarea
                v-model="data.notifyInvoiceTemplate"
                :state="getValidationState(validationContext)"
                rows="10"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Description -->
          <validation-provider #default="validationContext" name="" rules="">
            <b-form-group
              :label="t('Mẫu xác nhận thanh toán qua Zalo BOT/Zalo OA')"
            >
              <b-form-textarea
                v-model="data.notifyPaymentTemplate"
                :state="getValidationState(validationContext)"
                rows="10"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t("Lưu") }}
      </b-button>
    </b-form>

    <b-row>
      <b-col cols="12">
        <attribute-table />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormRadio,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import { required, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { parseDateToString, updateUserConfiguration } from "@/auth/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SelectDateTime from "@/views/components/SelectDateTime.vue";
import AttributeTable from "../../general/AttributeTable.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    BFormGroup,
    BFormInput,
    SelectDateTime,
    BFormTextarea,
    AttributeTable,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        autoNotificationNextBillingDate: false,
        autoNotificationNextBillingDateAt: null,
        autoNotificationInvoiceDueDate: false,
        autoNotificationInvoiceDueDateDays: "0",
        notifyInvoiceTemplate: "",
        notifyPaymentTemplate: "",
        notifyWithQrcode: true,
        sendLatestInvoiceOnly: false,
        applyPaymentConfirmTable: false,
        checkTuitionExpired: true,
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append(
        "autoNotificationNextBillingDate",
        this.data.autoNotificationNextBillingDate
      );
      data.append(
        "autoNotificationNextBillingDateAt",
        parseDateToString(
          this.data.autoNotificationNextBillingDateAt,
          "DD-MM-YYYY HH:mm"
        )
      );
      data.append(
        "autoNotificationInvoiceDueDate",
        this.data.autoNotificationInvoiceDueDate
      );
      data.append(
        "autoNotificationInvoiceDueDateDays",
        this.data.autoNotificationInvoiceDueDateDays
      );
      data.append(
        "notifyInvoiceTemplate",
        this.data.notifyInvoiceTemplate ?? ""
      );
      data.append(
        "notifyPaymentTemplate",
        this.data.notifyPaymentTemplate ?? ""
      );
      data.append("notifyWithQrcode", this.data.notifyWithQrcode);
      data.append(
        "sendLatestInvoiceOnly",
        this.data.sendLatestInvoiceOnly ?? false
      );
      data.append(
        "applyPaymentConfirmTable",
        this.data.applyPaymentConfirmTable ?? false
      );
      data.append("checkTuitionExpired", this.data.checkTuitionExpired ?? true);

      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
